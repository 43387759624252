<template>
    <div class="wrapper-registro cover-login">
        <div class="popup">
            <ValidationObserver ref="form" v-slot="{failed,valid}">
                <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                    <h2 class="titulo left">Datos Club Sénior</h2>
                    
                    <div class="toggle">
                        <input type="checkbox" id="last_ufv_check" v-model="data.last_ufv_check">
                        <label for="last_ufv_check">Soy miembro de Club Sénior</label>
                    </div>


                    <div class="contenedor-input" v-if="data.last_ufv_check">
                        
                            <label for="">Último puesto en la UFV</label>
                            <input autocomplete="off" type="text" name="nombre"  v-model="data.last_ufv"
                                :class="classes">
                        
                    </div>


                    <div class="full-width row-center">
                        <button class="boton-azul" :loading="loading">Guardar</button>
                    </div>


                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';


    

    export default {
        name: 'mis-datos-personales',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: {
                'last_ufv' : ''
            },
            club_senior : false,
            loading: false
        }),
        computed: {
            ...mapGetters({
                dominios: 'getDominios'
            })
        },
        methods: {
            ...mapActions(['saveAlumni', 'getAlumni']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.saveAlumni(this.data).then(() => {
                            this.loading = false;
                        })
                    }
                });
            },
            setData() {
                this.getAlumni().then(r => {
                    this.data = r;
                    this.club_senior = (this.data.last_ufv.length != 0)
                });
            }
        },
        watch :{
            'club_senior'(){
                if(!this.club_senior){
                    this.data.last_ufv = '';
                }
            }
        }

    }
</script>